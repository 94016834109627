<template>
  <div class="banner-container">
    <div v-if="url && title" class="title">
      <h1>{{ title }}</h1>
      <f7-link :href="url">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>
    </div>

    <div class="container">
      <f7-swiper v-if="bannerList && bannerList.length > 0 && circular" :slides-per-view="'auto'" navigation :space-between="10" loop :autoplay="{ delay: 5000 }">
        <f7-swiper-slide v-for="banner in bannerList" :key="'pbn_' + banner.BannerId" style="width: auto !important">
          <CircularBanner :f7route="f7route" :f7router="f7router" :data="banner" />
        </f7-swiper-slide>
      </f7-swiper>

      <f7-swiper v-if="bannerList && bannerList.length > 0 && !circular" :slides-per-view="'auto'" navigation :space-between="10" :autoplay="{ delay: 5000 }" loop>
        <!-- :centered-slides="true" :loop="true" -->
        <f7-swiper-slide v-for="banner in bannerList" :key="'pbn_' + banner.BannerId">
          <BannerCardComponent :f7route="f7route" :f7router="f7router" :data="banner" />
        </f7-swiper-slide>
      </f7-swiper>

      <!-- <div v-if="divider && bannerList?.length > 0" class="linedivider"></div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import BannerCardComponent from "@/components/cards/BannerCardComponent.vue";
// import CircularBanner from "@/components/cards/CircularBanner.vue";

export default defineComponent({
  name: "BannerListComponent",
  components: {
    BannerCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "banner-card" */ /* webpackMode: "lazy" */ "@/components/cards/BannerCardComponent.vue")),
    CircularBanner: defineAsyncComponent(() => import(/* webpackChunkName: "banner-circular-card" */ /* webpackMode: "lazy" */ "@/components/cards/CircularBanner.vue")),
  },
  props: {
    circular: Boolean,
    type: String,
    divider: Boolean,
    size: { type: Number, default: 25 },
    title: String,
    url: String,
    mode: String,
    f7router: Object,
    f7route: Object,
  },
  setup(props) {
    const store = useStore();

    const languageCode = computed(() => store.getters["translation/getLanguage"]);

    let page = 1;
    let lastPage = 1;

    const searchValue = ref("");
    const bannerList = ref([]);

    const getData = async (reset = false) => {
      if (reset) {
        page = 1;
        lastPage = 1;
        bannerList.value = [];
      }

      let getParams = {
        Type: props.type,
        page: page,
        size: props.size,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: searchValue.value,
        LanguageCode: languageCode.value,
        mode: props.mode || "",
      };

      let ret = await get("/banner/list", getParams);

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          bannerList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getData() : false;
    };

    const search = (data) => {
      searchValue.value = data;
      bannerList.value = [];
      page = 1;

      getData();
    };

    onMounted(() => {
      getData();
    });

    return { bannerList, loadMore, search, getData };
  },
});
</script>

<style></style>
